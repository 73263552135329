import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase"; // Firebase config
import Narbar from "../Components/Navbar/Narbar";
import Layout from "../Components/Layout";
import Loan from "./../Assets/loang.png";
import { Link } from "react-router-dom";

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch blog data from Firestore
  const fetchBlogs = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "blogs"));
      const blogsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        slug: doc.slug, // Add slug to each blog
        ...doc.data(),
      }));
      setBlogs(blogsData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching blogs:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="relative w-full ">
        <div className=" w-full flex px-40  max-lg:px-5 max-xl:px-14 flex-col  mt-10 z-10">
          <Narbar />
          <div className="blog-list-container z-50 border">
            <h1 className="text-2xl font-bold mb-4">Blog List</h1>
            {blogs.length === 0 ? (
              <p>No blogs found</p>
            ) : (
              <ul className=" grid grid-cols-3 gap-10">
                {blogs.map((blog) => (
                  <li key={blog.id} className="mb-6 p-4 border rounded shadow ">
                    {blog.imageUrl && (
                      <img
                        src={blog.imageUrl}
                        alt="Blog cover"
                        className=" mb-4 h-[300px] w-[500px]"
                      />
                    )}
                    <h2 className="text-xl font-semibold">{blog.title}</h2>
                    <p className="text-gray-600 mb-2">{blog.summary}</p>

                    <Link to={`/blog/${blog.slug}`} state={{ id: blog.id }}>
                      xem thêm
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div className=" absolute -top-[400px] w-full max-md:-top-[600px] z-0">
          <img src={Loan} className=" w-full  h-[1500px] z-0" alt="loan" />
        </div>
      </div>
      <Layout />
    </>
  );
};

export default BlogList;
