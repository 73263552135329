import React from "react";
import stageFive1 from "../../Assets/MacBooAir.png";
import stageFive2 from "../../Assets/Ellipse135.png";

const StageFive = () => {
  return (
    <>
      <div className="flex flex-row w-full max-md:hidden relative z-50">
        <div className="w-[35%] z-50 text-[20px] font-arialR max-sx:text-[6px] max-sm:text-[6px] max-md:text-[10px] max-iphone-12:text-[10px] max-iphone-12-pro-max:text-[10px] max-pixel-5:text-[6px]  max-lg:text-[16px] max-xl:text-[20px] max-2xl:text-[20px]">
          <h1 className="text-[36px] text-[#EEDB7D] font-arialB py-3 max-sx:text-[10px] max-sm:text-[10px] max-md:text-[14px] max-iphone-12:text-[14px] max-iphone-12-pro-max:text-[14px] max-pixel-5:text-[10px]  max-lg:text-[30px] max-xl:text-[36px] max-2xl:text-[36px]">
            HỆ THỐNG ERP
          </h1>
          <div>
            <p>Quản lý mua hàng (SCM)</p>
            <div className="py-2">
              <div className="w-[20%] border-t "></div>
            </div>
          </div>
          <div>
            <p>Quản lý dự án (PM)</p>
            <div className="py-2">
              <div className="w-[20%] border-t "></div>
            </div>
          </div>
          <div>
            <p>Quản lý tuân thủ (CM)</p>
            <div className="py-2">
              <div className="w-[20%] border-t "></div>
            </div>
          </div>
          <div>
            <p>Tích hợp E-commerce (ECI)</p>
            <div className="py-2">
              <div className="w-[20%] border-t "></div>
            </div>
          </div>
          <div>
            <p>Quản lý sản xuất (MRP)</p>
            <div className="py-2">
              <div className="w-[20%] border-t "></div>
            </div>
          </div>
          <div>
            <p>Quản lý khách hàng (CRM)</p>
            <div className="py-2">
              <div className="w-[20%] border-t "></div>
            </div>
          </div>
          <div>
            <p>Quản lý nghiệp vụ (CM)</p>
            <div className="py-2">
              <div className="w-[20%] border-t "></div>
            </div>
          </div>
          <div>
            <p>Quản lý nguồn nhân lực (HRM)</p>
            <div className="py-2">
              <div className="w-[20%] border-t "></div>
            </div>
          </div>
          <div>
            <p>Quản lý tài chính (FM)</p>
            <div className="py-2">
              <div className="w-[20%] border-t "></div>
            </div>
          </div>
          <div>
            <p>Quản lý kho bãi (IM)</p>
          </div>
        </div>

        <div className="w-[35%] space-y-10 z-50">
          <div className="text-[20px] font-arialR max-sx:text-[6px] max-sm:text-[6px] max-md:text-[10px] max-iphone-12:text-[10px] max-iphone-12-pro-max:text-[10px] max-pixel-5:text-[6px]  max-lg:text-[16px] max-xl:text-[20px] max-2xl:text-[20px]">
            <h1 className="text-[36px] text-[#EEDB7D] font-arialB py-3 max-sx:text-[10px] max-sm:text-[10px] max-md:text-[14px] max-iphone-12:text-[14px] max-iphone-12-pro-max:text-[14px] max-pixel-5:text-[10px]  max-lg:text-[30px] max-xl:text-[36px] max-2xl:text-[36px]">
              E-COMMERCE
            </h1>
            <div>
              <p>Cửa hàng trực tuyến</p>
              <div className="py-2">
                <div className="w-[20%] border-t "></div>
              </div>
            </div>
            <div>
              <p>Quản lý kho hàng</p>
              <div className="py-2">
                <div className="w-[20%] border-t "></div>
              </div>
            </div>
            <div>
              <p>Quản lí khách hàng</p>
              <div className="py-2">
                <div className="w-[20%] border-t "></div>
              </div>
            </div>
            <div>
              <p>Tích hợp thanh toán</p>
              <div className="py-2">
                <div className="w-[20%] border-t "></div>
              </div>
            </div>
          </div>
          <div className="text-[20px] font-arialR max-sx:text-[6px] max-sm:text-[6px] max-md:text-[10px] max-iphone-12:text-[10px] max-iphone-12-pro-max:text-[10px] max-pixel-5:text-[6px]  max-lg:text-[16px] max-xl:text-[20px] max-2xl:text-[20px]">
            <h1 className="text-[36px] text-[#EEDB7D] font-arialB py-3 max-sx:text-[10px] max-sm:text-[10px] max-md:text-[14px] max-iphone-12:text-[14px] max-iphone-12-pro-max:text-[14px] max-pixel-5:text-[10px]  max-lg:text-[30px] max-xl:text-[36px] max-2xl:text-[36px]">
              LANDING PAGE
            </h1>
            <div>
              <p>Thiết kế tiêu chuẩn</p>
              <div className="py-2">
                <div className="w-[20%] border-t "></div>
              </div>
            </div>
            <div>
              <p>Marketing và SEO</p>
              <div className="py-2">
                <div className="w-[20%] border-t "></div>
              </div>
            </div>
            <div>
              <p>Thu thập thông tin khách hàng</p>
            </div>
          </div>
        </div>
        <div className="absolute -top-[180px] -right-[250px] z-10 ">
          <img src={stageFive1} alt="stageFive1" />
        </div>
        <div className="absolute -top-[1000px] -right-[250px] z-0  ">
          <img src={stageFive2} className="h-[2000px]" alt="stage Five" />
        </div>
      </div>

      {/* mobile */}
      <div className="hidden max-md:block relative">
        <div className="flex flex-col space-y-7">
          <div>
            <h1 className="text-[36px] text-[#EEDB7D] font-arialB py-3 max-sx:text-[10px] max-sm:text-[10px] max-md:text-[14px] max-iphone-12:text-[14px] max-iphone-12-pro-max:text-[14px] max-pixel-5:text-[10px]  max-lg:text-[30px] max-xl:text-[36px] max-2xl:text-[36px]">
              HỆ THỐNG ERP
            </h1>
            <div className="w-full z-50 flex flex-row text-[20px] font-arialR max-sx:text-[6px] max-sm:text-[6px] max-md:text-[10px] max-iphone-12:text-[10px] max-iphone-12-pro-max:text-[10px] max-pixel-5:text-[6px]  max-lg:text-[16px] max-xl:text-[20px] max-2xl:text-[20px]">
              <div className="w-[40%] z-50">
                <div>
                  <p>Quản lý mua hàng (SCM)</p>
                  <div className="py-2">
                    <div className="w-[40%] border-t "></div>
                  </div>
                </div>
                <div>
                  <p>Quản lý dự án (PM)</p>
                  <div className="py-2">
                    <div className="w-[40%] border-t "></div>
                  </div>
                </div>
                <div>
                  <p>Quản lý tuân thủ (CM)</p>
                  <div className="py-2">
                    <div className="w-[40%] border-t "></div>
                  </div>
                </div>
                <div>
                  <p>Tích hợp E-commerce (ECI)</p>
                  <div className="py-2">
                    <div className="w-[40%] border-t "></div>
                  </div>
                </div>
                <div>
                  <p>Quản lý nguồn nhân lực (HRM)</p>
                </div>
              </div>

              <div className="w-[40%] z-50">
                <div>
                  <p>Quản lý sản xuất (MRP)</p>
                  <div className="py-2">
                    <div className="w-[40%] border-t "></div>
                  </div>
                </div>
                <div>
                  <p>Quản lý khách hàng (CRM)</p>
                  <div className="py-2">
                    <div className="w-[40%] border-t "></div>
                  </div>
                </div>
                <div>
                  <p>Quản lý nghiệp vụ (CM)</p>
                  <div className="py-2">
                    <div className="w-[40%] border-t "></div>
                  </div>
                </div>
                <div>
                  <p>Quản lý tài chính (FM)</p>
                  <div className="py-2">
                    <div className="w-[40%] border-t "></div>
                  </div>
                </div>
                <div>
                  <p>Quản lý kho bãi (IM)</p>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full flex flex-row z-50">
            <div className="w-[40%] z-50 text-[20px] font-arialR max-sx:text-[6px] max-sm:text-[6px] max-md:text-[10px] max-iphone-12:text-[10px] max-iphone-12-pro-max:text-[10px] max-pixel-5:text-[6px]  max-lg:text-[16px] max-xl:text-[20px] max-2xl:text-[20px]">
              <h1 className="text-[36px] text-[#EEDB7D] font-arialB py-3 max-sx:text-[10px] max-sm:text-[10px] max-md:text-[14px] max-iphone-12:text-[14px] max-iphone-12-pro-max:text-[14px] max-pixel-5:text-[10px]  max-lg:text-[30px] max-xl:text-[36px] max-2xl:text-[36px]">
                E-COMMERCE
              </h1>
              <div>
                <p>Cửa hàng trực tuyến</p>
                <div className="py-2">
                  <div className="w-[40%] border-t "></div>
                </div>
              </div>
              <div>
                <p>Quản lý kho hàng</p>
                <div className="py-2">
                  <div className="w-[40%] border-t "></div>
                </div>
              </div>
              <div>
                <p>Quản lý khách hàng</p>
                <div className="py-2">
                  <div className="w-[40%] border-t "></div>
                </div>
              </div>
              <div>
                <p>Tích hợp thanh toán</p>
              </div>
            </div>

            <div className="w-[40%] z-50 text-[20px] font-arialR max-sx:text-[6px] max-sm:text-[6px] max-md:text-[10px] max-iphone-12:text-[10px] max-iphone-12-pro-max:text-[10px] max-pixel-5:text-[6px]  max-lg:text-[16px] max-xl:text-[20px] max-2xl:text-[20px]">
              <h1 className="text-[36px] text-[#EEDB7D] font-arialB py-3 max-sx:text-[10px] max-sm:text-[10px] max-md:text-[14px] max-iphone-12:text-[14px] max-iphone-12-pro-max:text-[14px] max-pixel-5:text-[10px]  max-lg:text-[30px] max-xl:text-[36px] max-2xl:text-[36px]">
                LANDING PAGE
              </h1>
              <div>
                <p>Thiết kế tiêu chuẩn</p>
                <div className="py-2">
                  <div className="w-[40%] border-t "></div>
                </div>
              </div>
              <div>
                <p>Marketing và SEO</p>
                <div className="py-2">
                  <div className="w-[40%] border-t "></div>
                </div>
              </div>
              <div>
                <p>Thu thập thông tin khách hàng</p>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute top-10 -right-[50px] z-10">
          <img src={stageFive1} className="w-[300px]" alt="stageFive1" />
        </div>
        <div className="absolute -top-40 -right-[50px] z-0">
          <img src={stageFive2} alt="StageFive2" />
        </div>
      </div>
    </>
  );
};

export default StageFive;
