import React from "react";
import main from "../../Assets/pepes.png";
import { Link } from "react-router-dom";

const Narbar = ({ scrollToStageFour, scrollToStageFive, scrollToStageSix }) => {
  return (
    <div className="flex items-center justify-between relative z-20">
      <div>
        <Link to={"/"}>
          <img src={main} className="max-md:w-[90px]" alt="main" />
        </Link>
      </div>
      <div className="z-30 flex items-center text-[24px] space-x-10 max-sm:space-x-3 max-md:space-x-3 max-sx:text-[8px] max-sm:text-[12px] max-md:text-[16px] max-lg:text-[20px] max-xl:text-[24px] max-2xl:text-[24px] max-iphone-12:text-[11px] max-iphone-12-pro-max:text-[12px] max-pixel-5:text-[8px]">
        <button onClick={scrollToStageFour} className="hover:text-[#C8AB64]">
          Dịch Vụ
        </button>
        <div className="border h-1.5 w-1.5 bg-white rounded-full"></div>
        <button onClick={scrollToStageFive} className="hover:text-[#C8AB64]">
          Dự Án
        </button>
        <div className="border h-1.5 w-1.5 bg-white rounded-full"></div>
        <button onClick={scrollToStageSix} className="hover:text-[#C8AB64]">Liên Hệ</button>
        <div className="border h-1.5 w-1.5 bg-white rounded-full"></div>
        <button className="hover:text-[#C8AB64]">
          <Link to={"/"}>Blog</Link>
        </button>
      </div>
    </div>
  );
};

export default Narbar;
