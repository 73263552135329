import StageSix from "./StageSix/StageSix";
import bgtes from "../Assets/Rectangle-59.png";

const Layout = ({ children }) => {
    return (
      <>
        <div>{children}</div>
        <div className="relative z-10">
          <div className="w-full flex px-40 pb-20 max-lg:px-5 max-xl:px-14 z-50">
            <StageSix />
          </div>
          <div className="absolute bottom-0 z-0">
            <img src={bgtes} className="w-[3000px]" alt="bgtes" />
          </div>
        </div>
      </>
    );
  };
  
  export default Layout;