import React from "react";

const StageThree = () => {
  return (
    <div className="flex flex-row w-full space-x-10 max-md:space-x-3 ">
      <div className="w-[40%] border border-[#C6AC6C] px-[9%] py-20 max-md:py-6 rounded-[20px] max-md:px-[3%] max-lg:px-[4%] max-xl:px-[6%] max-2xl:px-[9%] ">
        <h1 className="font-arialB text-[36px] text-[#EEDB7D] max-sx:text-[10px] max-sm:text-[10px] max-md:text-[14px] max-iphone-12:text-[14px] max-iphone-12-pro-max:text-[14px] max-pixel-5:text-[10px]  max-lg:text-[30px] max-xl:text-[36px] max-2xl:text-[36px]">
          GIẢI PHÁP
        </h1>
        <div className="pt-1 max-md:py-2">
          <div className="w-24 border max-md:hidden"></div>
          <div className="w-10 border hidden max-md:block"></div>
        </div>
        <p className="text-[18px] max-md:mt-1 font-arialR mt-5 max-sx:text-[6px] max-sm:text-[6px] max-md:text-[10px] max-iphone-12:text-[10px] max-iphone-12-pro-max:text-[10px] max-pixel-5:text-[6px]  max-lg:text-[16px] max-xl:text-[18px] max-2xl:text-[18px]">
          Golden Cloud Technology luôn cống hiến hết mình cho ra những sản phẩm
          với tiêu chí tốt và chi phí phải chăng.
        </p>
      </div>
      <div className="w-[60%] border border-[#C6AC6C] px-[9%] py-20 max-md:py-6 rounded-[20px] max-md:px-[3%] max-lg:px-[4%] max-xl:px-[6%] max-2xl:px-[9%]">
        <h1 className="font-arialB text-[36px] text-[#EEDB7D] max-sx:text-[10px] max-sm:text-[10px] max-md:text-[14px] max-iphone-12:text-[14px] max-iphone-12-pro-max:text-[14px] max-pixel-5:text-[10px]  max-lg:text-[30px] max-xl:text-[36px] max-2xl:text-[36px]">
          QUÁ TRÌNH
        </h1>
        <div className="pt-1 max-md:py-2">
          <div className="w-24 border max-md:hidden"></div>
          <div className="w-10 border hidden max-md:block"></div>
        </div>
        <div className="text-[18px] max-md:mt-1 font-arialR mt-5 max-sx:text-[6px] max-sm:text-[6px] max-md:text-[10px] max-iphone-12:text-[10px] max-iphone-12-pro-max:text-[10px] max-pixel-5:text-[6px]  max-lg:text-[16px] max-xl:text-[18px] max-2xl:text-[18px]">
          <p>Thu thập và đánh giá </p>
          <p>Phân tích và tối ưu hoá giải pháp </p>
          <p>Tham vấn và lên kế hoạch xây dựng giải pháp </p>
          <p>
            Triển khai và thu thập thông tin - Bảo dưỡng và phát triển hậu kỳ
          </p>
        </div>
      </div>
    </div>
  );
};

export default StageThree;
