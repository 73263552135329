import React from "react";
import stageSix from "../../Assets/pos2.png";
import fb from "../../Assets/image6.png";
import ins from "../../Assets/image7.png";
import mail from "../../Assets/image8.png";

const StageSix = () => {
  return (
    <>
      <div className="mt-[200px] w-full max-md:hidden relative z-50">
        <div className="text-center w-full flex justify-center z-50">
          <div className="w-[60%] border border-[#F7E1AC]"></div>
        </div>
        <div className="w-full flex justify-center z-0">
          <img src={stageSix} alt="stageSix" />
        </div>
        <div className="w-full flex justify-between">
          <div className="w-[33%] ">
            <h1 className="text-[24px] font-arialB">Địa Chỉ</h1>
            <p className="w-[50%] text-[20px] font-arialR z-50">
              330/15 Phan Đình Phùng, phường 1, quận Phú Nhuận.
            </p>
          </div>
          <div className="w-[33%]  flex justify-center z-50">
            <div className="text-center">
              <h1 className="text-[24px] font-arialB z-50">Liên Hệ</h1>
              <p className="text-[20px] font-arialR">
                SĐT:{" "}
                <a
                  href="tel:0876827051"
                  className="hover:text-blue-500 hover:underline"
                >
                  0876827051
                </a>
              </p>
              <p className="text-[20px] font-arialR">
                Gmail:
                <a
                  href="mailto:contact@goldencloudsolution.online"
                  className="hover:text-blue-500 hover:underline"
                >
                  contact@goldencloudsolution.online
                </a>
              </p>
            </div>
          </div>
          <div className="w-[33%] flex justify-center items-center space-x-5 z-50">
            <a
              href="https://www.facebook.com/profile.php?id=61564832794531"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={fb} alt="Facebook" />
            </a>
            <a
              href="https://www.instagram.com/gctechnology2705/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={ins} alt="Instagram" />
            </a>
            <img src={mail} alt="Email" />
          </div>
        </div>
      </div>

      <div className="mt-[100px] w-full hidden max-md:block ">
        <div className="text-center w-full flex justify-center">
          <div className="w-[50%] border border-[#F7E1AC]"></div>
        </div>
        <div className="w-full flex justify-center">
          <img src={stageSix} className="w-[100px]" alt="stageSix" />
        </div>
        <div className="w-full flex justify-between">
          <div className="w-[40%] ">
            <h1 className="text-[24px] font-arialB max-sx:text-[10px] max-sm:text-[10px] max-md:text-[14px] max-iphone-12:text-[14px] max-iphone-12-pro-max:text-[14px] max-pixel-5:text-[10px]  max-lg:text-[20px] max-xl:text-[24px] max-2xl:text-[24px]">
              Địa Chỉ
            </h1>
            <p className="w-[90%] text-[20px] font-arialR max-sx:text-[6px] max-sm:text-[6px] max-md:text-[10px] max-iphone-12:text-[10px] max-iphone-12-pro-max:text-[10px] max-pixel-5:text-[6px]  max-lg:text-[18px] max-xl:text-[20px] max-2xl:text-[20px]">
              330/15 Phan Đình Phùng, phường 1, quận Phú Nhuận.
            </p>
          </div>
          <div className="w-[60%]  flex justify-center">
            <div className="">
              <h1 className="text-[24px] font-arialB max-sx:text-[10px] max-sm:text-[10px] max-md:text-[14px] max-iphone-12:text-[14px] max-iphone-12-pro-max:text-[14px] max-pixel-5:text-[10px]  max-lg:text-[20px] max-xl:text-[24px] max-2xl:text-[24px]">
                Liên Hệ
              </h1>
              <p className="text-[20px] font-arialR max-sx:text-[6px] max-sm:text-[6px] max-md:text-[10px] max-iphone-12:text-[10px] max-iphone-12-pro-max:text-[10px] max-pixel-5:text-[6px]  max-lg:text-[18px] max-xl:text-[20px] max-2xl:text-[20px]">
                SĐT:{" "}
                <a
                  href="tel:0876827051"
                  className="hover:text-blue-500 hover:underline"
                >
                  0876827051
                </a>
              </p>
              <p className="text-[20px] font-arialR max-sx:text-[6px] max-sm:text-[6px] max-md:text-[10px] max-iphone-12:text-[10px] max-iphone-12-pro-max:text-[10px] max-pixel-5:text-[6px]  max-lg:text-[18px] max-xl:text-[20px] max-2xl:text-[20px]">
                Gmail:
                <a
                  href="mailto:contact@goldencloudsolution.online"
                  className="hover:text-blue-500 hover:underline"
                >
                  contact@goldencloudsolution.online
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center items-center space-x-5 mt-10 z-50">
          <a
            href="https://www.facebook.com/profile.php?id=61564832794531"
            target="_blank"
            rel="noopener noreferrer"
            className="z-50"
          >
            <img src={fb} alt="Facebook" className="w-[30px] z-50" />
          </a>
          <a
            href="https://www.instagram.com/gctechnology2705/"
            target="_blank"
            rel="noopener noreferrer"
            className="z-50"
          >
            <img src={ins} alt="Instagram" className="w-[30px]" />
          </a>
          <img src={mail} className="w-[30px] z-50" alt="mail" />
        </div>
      </div>
    </>
  );
};

export default StageSix;
