import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import loang from "../Assets/loang.png";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      navigate("/admin");
      console.log(userCredential.user);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="w-full h-screen flex items-center justify-center relative">
      <div className="z-0 absolute -top-[800px] w-full max-md:-top-[50px]">
        <img src={loang} className=" w-full z-0 " alt="loan" />
      </div>
      <div className="border  bg-[#29241B] py-10 px-28 rounded-xl z-10 shadow-2xl">
        <h1 className="text-center text-[35px] font-arialB pb-10 text-[#F5DF77]">
          Login
        </h1>
        <form onSubmit={handleLogin} className="flex flex-col space-y-9">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            required
            className="py-2 px-3 rounded-md text-black w-full"
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
            className="py-2 px-3 rounded-md text-black w-full"
          />
          <button
            type="submit"
            className="border border-[#F7E1AC] rounded-md py-2 text-[17px] font-arialB"
          >
            Đăng nhập
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
