import React from "react";
import stageOneV1 from "../../Assets/stageone.png";

const StageOne = () => {
  return (
    <div className="relative flex">
      <div className="text-[#F5DF77] font-arial z-20 relative">
        <h1 className="text-[60px] font-arialB max-sx:text-[16px] max-sm:text-[16px] max-md:text-[20px] max-iphone-12:text-[16px] max-iphone-12-pro-max:text-[18px] max-pixel-5:text-[16px]  max-lg:text-[50px] max-xl:text-[60px] max-2xl:text-[60px] ">
          GOLDEN CLOUD
        </h1>
        <h2 className="text-[48px] max-sx:text-[14px] max-sm:text-[14px] max-md:text-[18px] max-iphone-12:text-[14px] max-iphone-12-pro-max:text-[18px] max-pixel-5:text-[14px]  max-lg:text-[40px] max-xl:text-[48px] max-2xl:text-[48px] ">
          Với Tính Năng Mạnh Mẽ
        </h2>
        <p className="text-white text-[20px] py-5 font-helvetica tracking-widest max-sx:text-[8px] max-sm:text-[8px] max-md:text-[10px] max-iphone-12:text-[8px] max-iphone-12-pro-max:text-[10px] max-pixel-5:text-[8px] max-lg:text-[18px] max-xl:text-[20px] max-2xl:text-[20px] max-md:w-[60%]">
          Giúp doanh nghiệp giải quyết các bài toán khó khăn trong quản lí và
          vận hành
        </p>
        <button class="gradient-button text-white px-10 py-3 rounded-[200px] text-[16px] max-md:text-[10px] font-arialR max-md:px-7 max-md:py-1.5">
          Xem Thêm
        </button>
      </div>

      <div className="absolute -top-[500px] -right-[300px] z-10 max-md:-right-[50px] max-md:-top-[80px]">
        <img src={stageOneV1} className="max-md:w-[250px] " alt="stageOne" />
      </div>
    </div>
  );
};

export default StageOne;
