import React, { useRef } from "react";
import Narbar from "../Components/Navbar/Narbar";
import StageOne from "../Components/StageOne/StageOne";
import StageTwo from "../Components/StageTwo/StageTwo";
import StageThree from "../Components/StageThree/StageThree";
import StageFour from "../Components/StageFour/StageFour";
import StageFive from "../Components/StageFive/StageFive";
import loan from "./../Assets/loang.png";
import { Helmet } from "react-helmet";
import Layout from "../Components/Layout";

const Home = () => {
  const stageFourRef = useRef(null); // Reference for StageThree
  const stageFiveRef = useRef(null);
  const stageSixRef = useRef(null);
  const scrollToStageFour = () => {
    stageFourRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToStageFive = () => {
    stageFiveRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToStageSix = () => {
    stageSixRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Golden Cloud</title>
        <meta
          name="description"
          content="Golden Cloud cung cấp dịch vụ tạo trang web chuyên nghiệp, nhanh chóng, phù hợp cho mọi doanh nghiệp và cá nhân."
        />
        <link rel="canonical" href="https://goldencloudsolutions.online/" />
        <img
          src="https://firebasestorage.googleapis.com/v0/b/fir-7aeaa.appspot.com/o/blog-images%2Flogo512.png.png?alt=media&token=104c37f8-e298-4f39-96f6-da8a79c1646a"
          alt="dịch vụ xây dựng trang web chuyên nghiệp"
        />

        <meta
          property="og:title"
          content="Golden Cloud - Dịch Vụ Tạo Trang Web"
        />
        <meta
          property="og:description"
          content="Chúng tôi cung cấp giải pháp tạo website chuyên nghiệp cho doanh nghiệp và cá nhân."
        />
        <meta
          property="og:image"
          content="https://firebasestorage.googleapis.com/v0/b/fir-7aeaa.appspot.com/o/blog-images%2Flogo512.png.png?alt=media&token=104c37f8-e298-4f39-96f6-da8a79c1646a"
        />
        <meta property="og:url" content="https://goldencloudsolutions.online" />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Golden Cloud - Dịch Vụ Tạo Trang Web"
        />
        <meta
          name="twitter:description"
          content="Chúng tôi cung cấp giải pháp tạo website chuyên nghiệp cho doanh nghiệp và cá nhân."
        />
        <meta
          name="twitter:image"
          content="https://firebasestorage.googleapis.com/v0/b/fir-7aeaa.appspot.com/o/blog-images%2Flogo512.png.png?alt=media&token=104c37f8-e298-4f39-96f6-da8a79c1646a"
        />
        <link rel="preload" href={loan} as="image"></link>
      </Helmet>
      <div className="relative w-full ">
        <div className="z-20 w-full flex px-40  max-lg:px-5 max-xl:px-14 flex-col space-y-72 mt-10 max-md:space-y-16 ">
          <Narbar
            scrollToStageFour={scrollToStageFour}
            scrollToStageFive={scrollToStageFive}
            scrollToStageSix={scrollToStageSix}
          />

          <StageOne />
          <StageTwo />
          {/* Pass the ref to StageThree */}
          <div>
            <StageThree />
          </div>
          <div ref={stageFourRef}>
            <StageFour />
          </div>
          <div ref={stageFiveRef}>
            <StageFive />
          </div>
        </div>
        <div className="z-0 absolute -top-[400px] w-full max-md:-top-[50px]">
          <img src={loan} className=" w-full z-0 " alt="loan" loading="lazy" />
        </div>
        <div ref={stageSixRef}>
          <Layout />
        </div>
      </div>
    </>
  );
};

export default Home;
