import  { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';

const ProtectedRoute = ({ children }) => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        setUser(null);
        navigate('/login'); // Chuyển hướng đến trang đăng nhập nếu chưa đăng nhập
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  return user ? children : null; // Nếu đã đăng nhập, render component con, nếu chưa thì không render gì
};

export default ProtectedRoute;
