import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Blog from "./Pages/Blog";

import Register from "./Pages/Register";
import Login from "./Pages/Login";
import Admin from "./Pages/Admin";
import ProtectedRoute from "./Components/ProtectedRoute";
import CreateBlog from "./Pages/CreateBlog";
import BlogList from "./Pages/BlogList";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blog/:slug" element={<Blog />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/admin"
          element={
            <ProtectedRoute>
              <Admin />
            </ProtectedRoute>
          }
        />
        <Route
          path="/createBlog"
          element={
            <ProtectedRoute>
              <CreateBlog />
            </ProtectedRoute>
          }
        />
       <Route
          path="/blogList"
          element={
            <ProtectedRoute>
              <BlogList />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
