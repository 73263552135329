import React, { useEffect, useState } from "react";
import Narbar from "../Components/Navbar/Narbar";
import Loan from "./../Assets/loang.png";
import BlogCp from "../Components/Blog/BlogCp";
import Layout from "../Components/Layout";
import { collection, getDocs } from "firebase/firestore"; // Firestore functions
import { db } from "../firebase"; // Firebase config
import { useLocation, useParams } from "react-router-dom";

const Blog = () => {
  const { slug } = useParams(); // Get slug from the URL
  const [blogData, setBlogData] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation(); // Get location object
  const dataFromLink = location.state.id; // This will be "hello" based on your Link

  console.log(dataFromLink);
  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "blogs"));
        const blogs = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          slug: doc.slug, // Lấy slug từ Firestore
          ...doc.data(),
        }));

        const foundBlog = blogs.find((blog) => blog.slug === slug); // Tìm blog theo slug
        const idBlog = blogs.find((blog) => blog.id === dataFromLink);
        if (foundBlog && idBlog) {
          setBlogData(idBlog); // Set the blog data in state
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching blog data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogData();
  }, [slug]); // Fetch the data when the component mounts and when slug changes

  if (loading) {
    return <div>Loading...</div>; // Show loading indicator
  }

  if (!blogData) {
    return <div>No blog data found.</div>; // Show message if no blog is found
  }

  return (
    <>
      <div className="relative w-full ">
        <div className=" w-full flex px-40  max-lg:px-5 max-xl:px-14 flex-col mt-10 z-10">
          <Narbar />
          <BlogCp
            title={blogData.title}
            summary={blogData.summary}
            imageUrl={blogData.imageUrl}
            content={blogData.content} // Assuming content is in HTML format
          />
        </div>
        <div className=" absolute -top-[400px] w-full max-md:-top-[600px] z-0">
          <img src={Loan} className=" w-full  h-[1500px] z-0" alt="loan" />
        </div>
      </div>
      <Layout />
    </>
  );
};

export default Blog;
