import React from "react";
import stageFour1 from "../../Assets/Rectangle55.png";
import stageFour2 from "../../Assets/Rectangle54.png";
import stageFour3 from "../../Assets/Rectangle56.png";
import stageFour4 from "../../Assets/Rectangle57.png";
const StageFour = () => {
  return (
    <>
      <div className="max-md:hidden">
        <h1 className="text-[#FFDE95] text-[36px] font-arialB mb-10 max-sx:text-[10px] max-sm:text-[10px] max-md:text-[14px] max-iphone-12:text-[14px] max-iphone-12-pro-max:text-[14px] max-pixel-5:text-[10px]  max-lg:text-[30px] max-xl:text-[36px] max-2xl:text-[36px]">
          DỊCH VỤ
        </h1>
        <div className="flex flex-row space-x-10">
          <div className="w-[60%] flex flex-col space-y-10">
            <div className="w-full border bg-[#1E1E1E] rounded-[20px] border-[#C8AB64] px-[9%] py-10">
              <h1 className="text-[32px] text-[#F0DCAD] font-arialB max-sx:text-[10px] max-sm:text-[10px] max-md:text-[14px] max-iphone-12:text-[14px] max-iphone-12-pro-max:text-[14px] max-pixel-5:text-[10px]  max-lg:text-[30px] max-xl:text-[32px] max-2xl:text-[32px]">
                ERP
              </h1>
              <p className="text-[18px] font-arialR mt-3 max-sx:text-[6px] max-sm:text-[6px] max-md:text-[10px] max-iphone-12:text-[10px] max-iphone-12-pro-max:text-[10px] max-pixel-5:text-[6px]  max-lg:text-[16px] max-xl:text-[18px] max-2xl:text-[18px]">
                Quản lý các doanh nghiệp trực quan hiệu quả và mọi lúc mọi nơi
                luôn là mong muốn của nhà quản trị. Giải pháp hệ thống quản trị
                tài nguyên doanh nghiệp (Enterprise Resources Planning - ERP)
                của chúng tôi được xây dựng và phát triển dành riêng cho nhu cầu
                của từng doanh nghiệp trong khi vẫn đảm bảo thực hiện những tiêu
                chí trên.
              </p>
            </div>
            <div className="w-full border bg-[#C8AB64] rounded-[20px] border-[#C8AB64]  flex flex-row">
              <div className="w-[70%] px-[9%] py-10">
                <h1 className="text-[32px] text-[#1E1E1E] font-arialB max-sx:text-[10px] max-sm:text-[10px] max-md:text-[14px] max-iphone-12:text-[14px] max-iphone-12-pro-max:text-[14px] max-pixel-5:text-[10px]  max-lg:text-[30px] max-xl:text-[32px] max-2xl:text-[32px]">
                  E-COMMERCE
                </h1>
                <p className="text-[18px] font-arialR mt-3 text-[#000000] max-sx:text-[6px] max-sm:text-[6px] max-md:text-[10px] max-iphone-12:text-[10px] max-iphone-12-pro-max:text-[10px] max-pixel-5:text-[6px]  max-lg:text-[16px] max-xl:text-[18px] max-2xl:text-[18px]">
                  Golden Cloud Solutions cung cấp dịch vụ E-commerce toàn diện,
                  giúp doanh nghiệp dễ dàng thiết lập và quản lý cửa hàng trực
                  tuyến. Chúng tôi tối ưu từ thiết kế website, thanh toán đến
                  quản lý kho hàng, mang lại trải nghiệm mua sắm nhanh chóng và
                  hiệu quả.
                </p>
              </div>
              <div className="w-[30%] ">
                <img
                  src={stageFour1}
                  className="h-full rounded-r-[20px]"
                  alt="stageFour1"
                />
              </div>
            </div>
          </div>
          <div className="w-[40%] border flex flex-col rounded-[20px]">
            <div>
              <img
                src={stageFour2}
                className="w-full h-[320px] rounded-t-[20px]"
                alt="stageFour2"
              />
            </div>
            <div className="w-full flex flex-col items-center px-[9%] justify-center mt-10 ">
              <h1 className="text-[#F0DCAD] text-[32px] font-arialB py-3">
                WEBSITE
              </h1>
              <p className="text-[18px] font-arialR max-sx:text-[6px] max-sm:text-[6px] max-md:text-[10px] max-iphone-12:text-[10px] max-iphone-12-pro-max:text-[10px] max-pixel-5:text-[6px]  max-lg:text-[16px] max-xl:text-[18px] max-2xl:text-[18px]">
                Trang Web quảng bá hay còn gọi là Landing Page đóng vai trò là
                một tấm danh thiếp của doanh nghiệp trên môi trường số. Một
                trang quảng bá đẹp và hiện đại góp phần xây dựng niềm tin và tạo
                ấn tượng với khách hàng.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile */}
      <div className="hidden max-md:block">
        <h1 className="text-[#FFDE95] text-[10px] font-arialB mb-5 text-center max-sx:text-[10px] max-sm:text-[10px] max-md:text-[14px] max-iphone-12:text-[14px] max-iphone-12-pro-max:text-[14px] max-pixel-5:text-[10px]  max-lg:text-[30px] max-xl:text-[36px] max-2xl:text-[36px]">
          DỊCH VỤ
        </h1>
        <div className="flex flex-col space-x-10">
          <div className="w-full flex flex-col space-y-5">
            <div className="w-full border bg-[#1E1E1E] rounded-[20px] border-[#C8AB64] px-[5%] py-3 ">
              <h1 className="text-[10px] text-[#F0DCAD] font-arialB max-sx:text-[10px] max-sm:text-[10px] max-md:text-[14px] max-iphone-12:text-[14px] max-iphone-12-pro-max:text-[14px] max-pixel-5:text-[10px]  max-lg:text-[30px] max-xl:text-[32px] max-2xl:text-[32px]">
                ERP
              </h1>
              <p className="text-[6px] font-arialR mt-1 max-sx:text-[6px] max-sm:text-[6px] max-md:text-[10px] max-iphone-12:text-[10px] max-iphone-12-pro-max:text-[10px] max-pixel-5:text-[6px]  max-lg:text-[16px] max-xl:text-[18px] max-2xl:text-[18px]">
                Quản lý các doanh nghiệp trực quan hiệu quả và mọi lúc mọi nơi
                luôn là mong muốn của nhà quản trị. Giải pháp hệ thống quản trị
                tài nguyên doanh nghiệp (Enterprise Resources Planning - ERP)
                của chúng tôi được xây dựng và phát triển dành riêng cho nhu cầu
                của từng doanh nghiệp trong khi vẫn đảm bảo thực hiện những tiêu
                chí trên.
              </p>
            </div>
            <div className="w-full h-full border bg-[#C8AB64] rounded-[20px] border-[#C8AB64]  flex flex-row">
              <div className="w-[75%] px-[5%] py-3 ">
                <h1 className="text-[10px] text-[#1E1E1E] font-arialB max-sx:text-[10px] max-sm:text-[10px] max-md:text-[14px] max-iphone-12:text-[14px] max-iphone-12-pro-max:text-[14px] max-pixel-5:text-[10px]  max-lg:text-[30px] max-xl:text-[32px] max-2xl:text-[32px]">
                  E-COMMERCE
                </h1>
                <p className="text-[6px] font-arialR mt-1 text-[#000000] max-sx:text-[6px] max-sm:text-[6px] max-md:text-[10px] max-iphone-12:text-[10px] max-iphone-12-pro-max:text-[10px] max-pixel-5:text-[6px]  max-lg:text-[16px] max-xl:text-[18px] max-2xl:text-[18px]">
                  Golden Cloud Solutions cung cấp dịch vụ E-commerce toàn diện,
                  giúp doanh nghiệp dễ dàng thiết lập và quản lý cửa hàng trực
                  tuyến. Chúng tôi tối ưu từ thiết kế website, thanh toán đến
                  quản lý kho hàng, mang lại trải nghiệm mua sắm nhanh chóng và
                  hiệu quả.
                </p>
              </div>
              <div className="w-[25%] ">
                <img
                  src={stageFour3}
                  className="h-full w-full rounded-r-[20px] "
                  alt="stage Four3"
                />
              </div>
            </div>

            <div className="border flex flex-row rounded-[20px] border-[#C8AB64]">
              <div className=" w-[35%]">
                <img
                  src={stageFour4}
                  className="w-full h-full rounded-l-[20px]"
                  alt="stageFour4"
                />
              </div>
              <div className="w-[65%] px-[5%] py-3  ">
                <h1 className="text-[#FFC300] text-[32px] font-arialB py-1 max-sx:text-[10px] max-sm:text-[10px] max-md:text-[14px] max-iphone-12:text-[14px] max-iphone-12-pro-max:text-[14px] max-pixel-5:text-[10px]  max-lg:text-[30px] max-xl:text-[32px] max-2xl:text-[32px]">
                  WEBSITE
                </h1>
                <p className="text-[18px] font-arialR max-sx:text-[6px] max-sm:text-[6px] max-md:text-[10px] max-iphone-12:text-[10px] max-iphone-12-pro-max:text-[10px] max-pixel-5:text-[6px]  max-lg:text-[16px] max-xl:text-[18px] max-2xl:text-[18px]">
                  Trang Web quảng bá hay còn gọi là Landing Page đóng vai trò là
                  một tấm danh thiếp của doanh nghiệp trên môi trường số. Một
                  trang quảng bá đẹp và hiện đại góp phần xây dựng niềm tin và
                  tạo ấn tượng với khách hàng.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StageFour;
