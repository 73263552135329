import React from "react";

const StageTwo = () => {
  return (
    <div>
      <div className="mt-28 flex items-center justify-center flex-col max-sx:mt-0 max-sm:mt-0 max-md:mt-0">
        <h1 className="text-[#EEDB7D] font-arialB text-[36px] max-sx:text-[10px] max-sm:text-[10px] max-md:text-[14px] max-iphone-12:text-[14px] max-iphone-12-pro-max:text-[14px] max-pixel-5:text-[10px]  max-lg:text-[30px] max-xl:text-[36px] max-2xl:text-[36px]">
          CHÚNG TÔI
        </h1>
        <div className="py-5 max-md:py-2">
          <div className="w-24 border max-md:hidden"></div>
          <div className="w-16 border hidden max-md:block"></div>
        </div>
        <p className="text-center font-arialR px-[10%] text-[20px] max-sx:text-[6px] max-sm:text-[6px] max-md:text-[10px] max-iphone-12:text-[10px] max-iphone-12-pro-max:text-[10px] max-pixel-5:text-[6px]  max-lg:text-[16px] max-xl:text-[20px] max-2xl:text-[20px]">
          Golden Cloud Technology chúng tôi nâng cao hiệu quả kinh doanh bằng
          các giải pháp phần mềm toàn diện. Từ hệ thống CRM và HRM trực quan đến
          tích hợp MRP và WMS mạnh mẽ, chúng tôi điều chỉnh mọi giải pháp để đáp
          ứng nhu cầu cụ thể của bạn. Chuyên môn của chúng tôi đảm bảo sản phẩm
          của chúng tôi có thiết kế liền mạch, thân thiện với người dùng, giúp
          tối ưu hoá hoạt động của bạn. Trải nghiệm sự đổi mới, dịch vụ tuỳ
          chỉnh và kết quả ấn tượng khi sử dụng dịch vụ của Golden Cloud
          Technology.
        </p>
      </div>
    </div>
  );
};

export default StageTwo;
