import React, { useState } from 'react';
import { db, storage } from '../firebase'; // Import Firebase config
import { addDoc, collection } from 'firebase/firestore'; // Firestore
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'; // Storage
import ReactQuill from 'react-quill'; // Import React Quill
import 'react-quill/dist/quill.snow.css'; // Import CSS for Quill

const CreateBlog = () => {
  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [summary, setSummary] = useState('');
  const [content, setContent] = useState(''); // Content in HTML format
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleSubmit = async () => {
    if (!title || !content || !author) {
      alert('Please fill in all required fields');
      return;
    }

    setLoading(true);

    try {
      let uploadedImageUrl = '';
      if (image) {
        const storageRef = ref(storage, `blog-images/${image.name}`);
        const uploadTask = uploadBytesResumable(storageRef, image);

        await new Promise((resolve, reject) => {
          uploadTask.on(
            'state_changed',
            null,
            reject,
            async () => {
              uploadedImageUrl = await getDownloadURL(uploadTask.snapshot.ref);
              resolve();
            }
          );
        });
      }

      // Save blog data to Firestore
      await addDoc(collection(db, 'blogs'), {
        title,
        author,
        summary,
        content,
        imageUrl: uploadedImageUrl,
        createdAt: new Date(),
      });

      alert('Blog created successfully');
      setTitle('');
      setAuthor('');
      setSummary('');
      setContent('');
      setImage(null);
      setLoading(false);
    } catch (error) {
      console.error("Error creating blog:", error);
      setLoading(false);
    }
  };

  const quillModules = {
    toolbar: [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['link', 'image'], // Add image button
    ],
  };

  return (
    <div className="create-blog-page p-4">
      <h1 className="text-2xl font-bold mb-4">Create a New Blog</h1>
      
      <div className="mb-4">
        <label htmlFor="title" className="block mb-2 font-semibold">Blog Title:</label>
        <input
          type="text"
          id="title"
          className="w-full p-2 border border-gray-300 rounded text-black"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter blog title"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="author" className="block mb-2 font-semibold">Author:</label>
        <input
          type="text"
          id="author"
          className="w-full p-2 border border-gray-300 rounded text-black"
          value={author}
          onChange={(e) => setAuthor(e.target.value)}
          placeholder="Enter author name"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="summary" className="block mb-2 font-semibold">Summary:</label>
        <textarea
          id="summary"
          className="w-full p-2 border border-gray-300 rounded text-black"
          value={summary}
          onChange={(e) => setSummary(e.target.value)}
          placeholder="Enter a short summary"
        />
      </div>

      {/* Blog content with React Quill */}
      <div className="mb-4">
        <label htmlFor="content" className="block mb-2 font-semibold">Blog Content:</label>
        <ReactQuill
          value={content}
          onChange={setContent}
          modules={quillModules}
          placeholder="Enter blog content..."
          className="bg-white text-black"
        />
      </div>

      {/* Featured image */}
      <div className="mb-4">
        <label htmlFor="image" className="block mb-2 font-semibold">Blog Image:</label>
        <input
          type="file"
          id="image"
          className="w-full p-2 text-black"
          onChange={handleImageChange}
        />
        {image && <img src={URL.createObjectURL(image)} alt="Blog preview" className="mt-4 w-full h-64 object-cover rounded-lg" />}
      </div>

      <button
        className={`px-6 py-2 text-white rounded ${loading ? 'bg-gray-500' : 'bg-blue-500 hover:bg-blue-600 transition duration-200'}`}
        onClick={handleSubmit}
        disabled={loading}
      >
        {loading ? 'Publishing...' : 'Publish Blog'}
      </button>
    </div>
  );
};

export default CreateBlog;
