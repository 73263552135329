import React from "react";

const BlogCp = ({ title, summary, imageUrl, content }) => {

  const modifiedContent = content
  .replace(/<h1>/g, '<h1 class="text-4xl font-bold text-[#F5DF77] pt-10 max-md:pt-5 max-sx:text-[16px] max-sm:text-[16px] max-md:text-[20px] max-iphone-12:text-[16px] max-iphone-12-pro-max:text-[18px] max-pixel-5:text-[16px] max-lg:text-[50px] max-xl:text-[60px] max-2xl:text-[60px]">')
  .replace(/<h2>/g, '<h2 class="text-2xl font-semibold text-gray-700 pt-10 max-md:pt-5 max-sx:text-[13px] max-sm:text-[13px] max-md:text-[16px] max-iphone-12:text-[13px] max-iphone-12-pro-max:text-[14px] max-pixel-5:text-[14px] max-lg:text-[50px] max-xl:text-[60px] max-2xl:text-[60px]">')
  .replace(/<h3>/g, '<h2 class="text-xl font-semibold text-gray-700 pt-10 max-md:pt-5 max-sx:text-[13px] max-sm:text-[13px] max-md:text-[16px] max-iphone-12:text-[13px] max-iphone-12-pro-max:text-[14px] max-pixel-5:text-[14px] max-lg:text-[50px] max-xl:text-[60px] max-2xl:text-[60px]">');
  return (
    <div className="z-40">
      <div className="w-full flex justify-center text-[61px] font-arialB text-[#F5DF77] pt-10 pb-20 max-md:pt-5 max-md:pb-10 max-sx:text-[16px] max-sm:text-[16px] max-md:text-[20px] max-iphone-12:text-[16px] max-iphone-12-pro-max:text-[18px] max-pixel-5:text-[16px] max-lg:text-[50px] max-xl:text-[60px] max-2xl:text-[60px] ">
        {title} {/* Dynamic title */}
      </div>
      <div className="w-full flex flex-row space-x-20 items-center justify-center max-md:space-x-2">
        <div className="w-[40%] text-[20px] font-arialR max-sx:text-[8px] max-sm:text-[8px] max-md:text-[10px] max-iphone-12:text-[8px] max-iphone-12-pro-max:text-[10px] max-pixel-5:text-[8px] max-lg:text-[18px] max-xl:text-[20px] max-2xl:text-[20px]">
          {summary} {/* Dynamic summary */}
        </div>
        <div className="w-[60%]">
          {imageUrl && (
            <img
              src={imageUrl}
              alt="Blog visual"
              className="w-full h-auto object-cover"
            />
          )}
        </div>
      </div>
      <div className="py-20 max-md:py-10 text-[20px] font-arialR max-sx:text-[8px] max-sm:text-[8px] max-md:text-[10px] max-iphone-12:text-[8px] max-iphone-12-pro-max:text-[10px] max-pixel-5:text-[8px] max-lg:text-[18px] max-xl:text-[20px] max-2xl:text-[20px]">
        <div dangerouslySetInnerHTML={{ __html: modifiedContent }} /> {/* Dynamic content as HTML */}
      </div>
    </div>
  );
};

export default BlogCp;
